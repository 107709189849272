body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fdebe9;
  width: "100%";
}

@font-face {
  font-family: Caslons;
  src: url(./fonts/Caslons.ttf) format("truetype");
}

html {
  font-family: Caslons;
}

#root {
  font-family: Caslons;
}

a:link {
  color: #6a7a9f;
}
